import { useEffect } from 'react';
import { useIsLogged, useUser } from '@v2/store/rtk.user.selectors';

const MicrosoftAds = () => {
    const isLogged = useIsLogged();
    const user = useUser();

    useEffect(() => {
        if (process.env.ENV !== 'production') {
            return;
        }

        if (isLogged && user?.email) {
            window.uetq.push('set', {
                'pid': {
                    'em': user.email,
                    'ph': user.phone || ""
                }
            });
        }
    }, [isLogged, user]);

    return (<></>);
};

export default MicrosoftAds;