import dayjs from 'dayjs';
import { IBoxChildStage, ICartBox, ICartBoxLine } from '@v2/interfaces';
import { updateActiveChildBox } from '@app/store/rtk.cart.store';
import { useActiveChildBox } from '@app/store/cart.selectors';
import { useDispatch } from 'react-redux';
import cloneDeep from 'lodash.clonedeep';

const ageInMonthsLowerBound = 4;

function calculateAgeInMonths(birthdate: string) {
    const bdDate = dayjs(birthdate);
    const now = dayjs();

    return Math.floor(now.diff(bdDate, 'months', true));
}

// value order is based on screen order
const screenKeys = [
    'initial',
    'details',
    // 'email', // email is replaced to details screen according to Story 7416
    'milestones',
    'size',
    'overview',
];

function getScreenKeys(childBox: any) {
    if (!childBox?.survey) {
        return screenKeys;
    }

    const isLogged = (childBox?.parent?.isLoggedIn) ? true : false;
    const sKeys = screenKeys;

    if (isLogged) {
        const index = sKeys.indexOf('email');

        if (index > -1) {
            sKeys.splice(index, 1);
        }
    }

    return sKeys;
}

function useUpdateActiveChild() {
    const dispatch = useDispatch();
    const childBox = useActiveChildBox();
    const activeScreen = !!childBox?.survey?.activeScreen ? childBox.survey.activeScreen : null;

    return (direction?: string, box?: ICartBox, stage?: IBoxChildStage) => {
        // Rewrite new box data
        const newBox = cloneDeep(box || childBox);

        // Process stage products
        if (stage && stage.blueprint && (stage.products || stage.veggieProducts)) {
            const lines: { [key: number]: ICartBoxLine } = {};
            const veggieLines: { [key: number]: ICartBoxLine } = {};

            for (const item of stage.products) {
                lines[item.product.id] = {
                    count: item.quantity,
                    product: item.product,
                };
            }

            for (const item of stage.veggieProducts) {
                veggieLines[item.product.id] = {
                    count: item.quantity,
                    product: item.product,
                };
            }

            newBox.blueprint = cloneDeep(stage.blueprint);
            newBox.lines = cloneDeep((newBox.child && newBox.child.isVegan) ? veggieLines : lines);
            newBox.stage = {
                id: stage.id,
                blueprint: cloneDeep(stage.blueprint),
                description: cloneDeep(stage.description),
                category: cloneDeep(stage.category),
                nutrients: cloneDeep(stage.nutrients),
                developmental: cloneDeep(stage.developmental),
                lines: cloneDeep(lines),
                veggieLines: cloneDeep(veggieLines)
            }
        }

        // Move to next screen
        if (direction && activeScreen) {
            const currentIndex = screenKeys.indexOf(activeScreen);
            const sKeys = getScreenKeys(childBox);
            const nextIndex = direction === 'next' ? currentIndex +1 : currentIndex -1;

            if (sKeys[nextIndex]) {
                newBox.survey.previousScreen = activeScreen;
                newBox.survey.activeScreen = sKeys[nextIndex];
            }
        }

        dispatch(updateActiveChildBox({ box: newBox }));
    };
}

export {
    ageInMonthsLowerBound,
    calculateAgeInMonths,
    useUpdateActiveChild
}
